<template>
  <div>
    <only-site-card :com_data="com_data">
      <template v-slot:item_edit>
        <ValidationObserver ref="observer">
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="2"
                ><span class="font-weight-bold">NEW表示/非表示</span></v-col
              >
              <v-col cols="12" sm="10">
                <v-row>
                  <v-col cols="6" sm="4" md="2">
                    <v-select
                      ref="new_flg"
                      v-model="cast_prop.new_flg"
                      name="new_flg"
                      :items="flgItem"
                      :no-data-text="items.new_flg.text"
                      :label="items.new_flg.text"
                      success
                      outlined
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mt-4 mb-10"></v-divider>
            <v-row>
              <v-col cols="12" sm="2"
                ><span class="font-weight-bold">UP表示/非表示</span></v-col
              >
              <v-col cols="12" sm="10">
                <v-row>
                  <v-col cols="6" sm="4" md="2">
                    <v-select
                      ref="up_flg"
                      v-model="cast_prop.up_flg"
                      name="up_flg"
                      :items="flgItem"
                      :no-data-text="items.up_flg.text"
                      :label="items.up_flg.text"
                      success
                      outlined
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mt-4 mb-10"></v-divider>
            <v-row>
              <v-col cols="12" sm="2"
                ><span class="font-weight-bold">入店日</span></v-col
              >
              <v-col cols="6" sm="3">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      ref="entry_date"
                      v-model="cast_prop.entry_date"
                      name="entry_date"
                      type="date"
                      :success="true"
                      :label="items.entry_date.text"
                      outlined
                      background-color="white"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mt-4 mb-10"></v-divider>
            <v-row>
              <v-col cols="12" sm="2"
                ><span class="font-weight-bold">体重</span></v-col
              >
              <v-col cols="12" sm="10">
                <v-row>
                  <v-col cols="6" sm="3" md="2">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      :name="items.weight.text"
                      :rules="items.weight.rules"
                    >
                      <v-text-field
                        ref="weight"
                        v-model="cast_prop.weight"
                        name="weight"
                        :error-messages="errors"
                        :success="valid"
                        :label="items.weight.text"
                        suffix="kg"
                        outlined
                        :counter="items.weight.counter"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mt-4 mb-8"></v-divider>
            <v-row>
              <v-col cols="12" sm="2"
                ><span class="font-weight-bold">女の子タイプ</span></v-col
              >
              <v-col cols="12" sm="10">
                女の子タイプを{{
                  items.lady_type.counter
                }}個以内で選択してください。
                <ValidationProvider
                  v-slot="{ errors, valid }"
                  :name="items.lady_type.text"
                  :rules="items.lady_type.rules"
                >
                  <div v-if="!valid" class="red--text">
                    {{ items.lady_type.counter }}個までしか選択出来ません
                  </div>
                  <v-row class="mt-2 mb-2">
                    <v-col
                      v-for="lady in lady_type"
                      :key="lady.label"
                      cols="4"
                      sm="3"
                      md="2"
                    >
                      <v-checkbox
                        class="mt-1"
                        ref="lady_type"
                        v-model="lady_selected"
                        name="lady_type"
                        :label="lady.label"
                        :value="lady.value"
                        :success="valid"
                        :error="!valid"
                        hide-details
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-divider class="mt-4 mb-8"></v-divider>
            <v-row>
              <v-col cols="12" sm="2">
                <span class="font-weight-bold">お店から</span>
                <p class="red--text mini">
                  未入力の場合、コメントタブの「<strong>お店コメント</strong>」の内容で更新します。
                </p>
              </v-col>
              <v-col cols="12" sm="10">
                <v-row>
                  <v-col cols="12">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      :name="items.shop_comment.text"
                      :rules="items.shop_comment.rules"
                    >
                      <v-textarea
                        ref="shop_comment"
                        v-model="cast_prop.shop_comment"
                        name="shop_comment"
                        :error-messages="errors"
                        :success="valid"
                        :label="items.shop_comment.text"
                        placeholder=""
                        rows="6"
                        outlined
                        :counter="items.shop_comment.counter"
                        background-color="white"
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mt-4 mb-8"></v-divider>
            <v-row>
              <v-col cols="12" sm="2">
                <span class="font-weight-bold">女の子から</span>
                <p class="red--text mini">
                  未入力の場合、コメントタブの「<strong>セラピストコメント</strong>」の内容で更新します。
                </p>
              </v-col>
              <v-col cols="12" sm="10">
                <v-row>
                  <v-col cols="12">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      :name="items.cast_comment.text"
                      :rules="items.cast_comment.rules"
                    >
                      <v-textarea
                        ref="cast_comment"
                        v-model="cast_prop.cast_comment"
                        name="cast_comment"
                        :error-messages="errors"
                        :success="valid"
                        :label="items.cast_comment.text"
                        placeholder=""
                        rows="6"
                        outlined
                        :counter="items.cast_comment.counter"
                        background-color="white"
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mt-4 mb-8"></v-divider>
            <v-row>
              <v-col cols="12" sm="2"
                ><span class="font-weight-bold">リダイレクト設定</span></v-col
              >
              <v-col cols="12" sm="10">
                <v-row>
                  <v-col cols="12">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      :name="items.redirect_url.text"
                      :rules="items.redirect_url.rules"
                    >
                      <v-text-field
                        ref="redirect_url"
                        v-model="cast_prop.redirect_url"
                        name="redirect_url"
                        :error-messages="errors"
                        :success="valid"
                        :label="items.redirect_url.text"
                        prefix="URL"
                        outlined
                        :counter="items.redirect_url.counter"
                        background-color="white"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      :name="items.redirect_url_sp.text"
                      :rules="items.redirect_url_sp.rules"
                    >
                      <v-text-field
                        ref="redirect_url_sp"
                        v-model="cast_prop.redirect_url_sp"
                        name="redirect_url_sp"
                        :error-messages="errors"
                        :success="valid"
                        :label="items.redirect_url_sp.text"
                        prefix="URL"
                        outlined
                        :counter="items.redirect_url_sp.counter"
                        background-color="white"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mt-4 mb-8"></v-divider>
            <v-row>
              <v-col cols="12" sm="2"
                ><span class="font-weight-bold">TwitterID</span></v-col
              >
              <v-col cols="12" sm="10">
                <v-row>
                  <v-col cols="12" sm="3">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      :name="items.twitter_id.text"
                      :rules="items.twitter_id.rules"
                    >
                      <v-text-field
                        ref="twitter_id"
                        v-model="cast_prop.twitter_id"
                        name="twitter_id"
                        :error-messages="errors"
                        :success="valid"
                        :label="items.twitter_id.text"
                        :hint="`Twitter IDは英数字と_のみ利用出来ます。IDは＠以降をご登録ください。`"
                        prefix="@"
                        persistent-hint
                        outlined
                        :counter="items.twitter_id.counter"
                        background-color="white"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row class="mt-2 mb-2">
                  <v-col cols="4" sm="3" md="3">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      :name="items.twitter_pc_top_open.text"
                      :rules="items.twitter_pc_top_open.rules"
                    >
                      <v-checkbox
                        class="mt-1"
                        ref="twitter_pc_top_open"
                        v-model="cast_prop.twitter_pc_top_open"
                        name="twitter_pc_top_open"
                        :label="items.twitter_pc_top_open.text"
                        value="1"
                        :success="valid"
                        :error="!valid"
                        hide-details
                      ></v-checkbox>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="4" sm="3" md="3">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      :name="items.twitter_sp_top_open.text"
                      :rules="items.twitter_sp_top_open.rules"
                    >
                      <v-checkbox
                        class="mt-1"
                        ref="twitter_sp_top_open"
                        v-model="cast_prop.twitter_sp_top_open"
                        name="twitter_sp_top_open"
                        :label="items.twitter_sp_top_open.text"
                        value="1"
                        :success="valid"
                        :error="!valid"
                        hide-details
                      ></v-checkbox>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </ValidationObserver>
      </template>
    </only-site-card>
  </div>
</template>

<script>
import {
  set,
  ref,
  reactive,
  toRefs,
  defineComponent,
} from "@vue/composition-api";
import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import utilFunc from "@/common/utilFunc.js";

export default defineComponent({
  props: ["com_data"],
  setup(props, ctx) {
    const CastPropsRepository = repositoryFactory.get("castProps");
    const state = reactive({
      errorElement: null,
      cast_prop: {},
      lady_selected: [],
      lady_type: {
        "lady-id-1": { label: "可愛い系", value: "1" },
        "lady-id-14": { label: "ギャル系", value: "14" },
        "lady-id-15": { label: "痴女", value: "15" },
        "lady-id-16": { label: "人妻系", value: "16" },
        "lady-id-17": { label: "スレンダー系", value: "17" },
        "lady-id-19": { label: "おっとり系", value: "19" },
        "lady-id-20": { label: "モデル系", value: "20" },
        "lady-id-21": { label: "巨乳系", value: "21" },
        "lady-id-22": { label: "ぽっちゃり系", value: "22" },
        "lady-id-23": { label: "女子大生系", value: "23" },
        "lady-id-13": { label: "素人系", value: "13" },
        "lady-id-12": { label: "天然系", value: "12" },
        "lady-id-11": { label: "癒し系", value: "11" },
        "lady-id-2": { label: "綺麗系", value: "2" },
        "lady-id-3": { label: "熟女系", value: "3" },
        "lady-id-4": { label: "セレブ系", value: "4" },
        "lady-id-5": { label: "女王様", value: "5" },
        "lady-id-6": { label: "Ｍ嬢", value: "6" },
        "lady-id-7": { label: "お姉様系", value: "7" },
        "lady-id-8": { label: "セクシー系", value: "8" },
        "lady-id-9": { label: "清楚系", value: "9" },
        "lady-id-10": { label: "ロリ系", value: "10" },
        "lady-id-24": { label: "OL系", value: "24" },
      },
      // 読み込み時データ保持(保存前比較用)
      keepData: {
        cast_prop: {},
      },
      twitter_pc_top_open_selected: null,
      twitter_sp_top_open_selected: null,
    });
    const flgItem = [
      { value: "0", text: "非表示" },
      { value: "1", text: "表示" },
    ];

    const items = {
      "new_flg": {
        text: "NEW表示/非表示",
        rules: "required",
        counter: "",
      },
      "up_flg": {
        text: "UP表示/非表示",
        rules: "required",
        counter: "",
      },
      "entry_date": {
        text: "入店日",
        rules: "date_format:yyyy-MM-dd",
        counter: "",
      },
      "weight": {
        text: "体重",
        rules: "numeric|max:3",
        counter: "3",
      },
      "lady_type": {
        text: "女の子タイプ",
        rules: "check_max:4",
        counter: "4",
      },
      "shop_comment": {
        text: "お店から",
        rules: "max:10000",
        counter: "10000",
      },
      "cast_comment": {
        text: "女の子から",
        rules: "max:10000",
        counter: "10000",
      },
      "redirect_url": {
        text: "PC",
        rules: "url|max:100",
        counter: "100",
      },
      "redirect_url_sp": {
        text: "スマホ",
        rules: "url|max:100",
        counter: "100",
      },
      "twitter_id": {
        text: "TwitterID",
        rules: "min:5|max:15|twitter",
        counter: "15",
      },
      "twitter_pc_top_open": {
        text: "PCトップ表示",
        rules: "",
        counter: "",
      },
      "twitter_sp_top_open": {
        text: "スマホトップ表示",
        rules: "",
        counter: "",
      },
    };

    const init = async () => {
      // cast_propにキーを設定
      for (let key in items) {
        set(state.cast_prop, key, null);
      }
      Object.keys(props.com_data.cast_prop).forEach(function (key) {
        set(state.cast_prop, key, props.com_data.cast_prop[key]);
      });
      // 読み込み時のデータを保持(保存前に比較するため)
      state.keepData.cast_prop = Object.assign({}, state.cast_prop);

      // セラピストの特徴をjsonから配列に変換しておく
      if (state.cast_prop.lady_type) {
        state.lady_selected = JSON.parse(state.cast_prop.lady_type);
      }
    };

    init();

    // チェック処理
    const checkValid = async () => {
      // 事前チェック処理
      state.errorElement = null;
      const isValid = await ctx.refs.observer.validate();
      if (!isValid) {
        let item_name = "";
        Object.keys(ctx.refs.observer.errors).forEach(function (key) {
          if (ctx.refs.observer.errors[key].length > 0) {
            item_name = key;
            return;
          }
        });
        const errorKey = utilFunc.getKeyByItemText(items, item_name);
        if (errorKey == "lady_type") {
          state.errorElement = ctx.refs[errorKey][0].$el;
        } else {
          state.errorElement = ctx.refs[errorKey].$el;
        }
      }
      return isValid;
    };

    const setErrorFocus = async () => {
      setTimeout(() => {
        state.errorElement.scrollIntoView({
          block: "center",
        });
      }, 300);
    };

    // 保存処理
    const save = async () => {
      // 更新前と比べて差分があれば更新処理

      for (let key in state.cast_prop) {
        if (key == "lady_type") {
          set(
            state.cast_prop,
            key,
            state.lady_selected.length == 0
              ? ""
              : JSON.stringify(state.lady_selected)
          );
        }
      }
      if (!utilFunc.compareObject(state.keepData.cast_prop, state.cast_prop)) {
        const prop_param = [];
        for (let key in state.cast_prop) {
          prop_param.push({
            cast_id: props.com_data.cast_id,
            key: props.com_data.prefix + "__" + key,
            value: state.cast_prop[key] ?? "",
          });
        }
        await CastPropsRepository.save_all({ cast_prop: prop_param })
          .then((response) => {
            if (response.data) {
              // データ追加OK
            }
          })
          .catch((error) => {
            throw (
              "ERROR:estama.vue/save CastPropsRepository.save_all (" +
              error +
              ")"
            );
          });
      }
      return true;
    };

    return {
      props,
      setting,
      flgItem,
      items,
      ...toRefs(state),
      checkValid,
      setErrorFocus,
      save,
    };
  },
});
</script>

<style scoped></style>
