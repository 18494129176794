<template>
  <div>
    <only-site-card :com_data="com_data">
      <template v-slot:item_edit>
        <ValidationObserver ref="observer">
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="2">
                <div class="font-weight-bold mb-2">女性からのメッセージ</div>
                <p class="red--text mini">
                  未入力の場合、コメントタブの「<strong>セラピストコメント</strong>」の内容で更新します。
                </p>
              </v-col>
              <v-col cols="12" sm="10">
                <v-row>
                  <v-col col="12">
                    <div class="mt-1 mb-1">
                      <span class="font-weight-bold text-align-middle"
                        >HTML用&nbsp;</span
                      ><v-btn
                        text
                        color="primary"
                        class="font-weight-bold pa-1 d-inline"
                        @click="onHtmlClear('cast')"
                        >HTMLをクリア</v-btn
                      >
                    </div>
                    <div class="">
                      <ckeditor-component
                        ref="refCommentByCastCkeditor"
                        :data="cast_prop.lady_lmsg"
                      ></ckeditor-component>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mt-4 mb-10"></v-divider>
            <v-row>
              <v-col cols="12" sm="2">
                <div class="font-weight-bold mb-2">店舗からのメッセージ</div>
                <p class="red--text mini">
                  未入力の場合、コメントタブの「<strong>お店コメント</strong>」の内容で更新します。
                </p>
              </v-col>
              <v-col cols="12" sm="10">
                <v-row>
                  <v-col col="12">
                    <div class="mt-1 mb-1">
                      <span class="font-weight-bold text-align-middle"
                        >HTML用&nbsp;</span
                      ><v-btn
                        text
                        color="primary"
                        class="font-weight-bold pa-1 d-inline"
                        @click="onHtmlClear('shop')"
                        >HTMLをクリア</v-btn
                      >
                    </div>
                    <div class="">
                      <ckeditor-component
                        ref="refCommentByShopCkeditor"
                        :data="cast_prop.lady_mesg"
                      ></ckeditor-component>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mt-4 mb-10"></v-divider>
            <v-row>
              <v-col cols="12" sm="2"
                ><span class="font-weight-bold">新人属性</span></v-col
              >
              <v-col cols="12" sm="10">
                <v-row>
                  <v-col cols="6" sm="4" md="2">
                    <v-select
                      ref="lady_rookie"
                      v-model="cast_prop.lady_rookie"
                      name="lady_rookie"
                      :items="rookieItem"
                      :no-data-text="items.lady_rookie.text"
                      :label="items.lady_rookie.text"
                      :hint="`新人選択で入店日から30日間は「新人アイコン」が付きます`"
                      persistent-hint
                      success
                      outlined
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mt-4 mb-10"></v-divider>
            <v-row>
              <v-col cols="12" sm="2"
                ><span class="font-weight-bold">入店日</span></v-col
              >
              <v-col cols="6" sm="3">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      ref="lady_nday"
                      v-model="cast_prop.lady_nday"
                      name="lady_nday"
                      type="date"
                      :success="true"
                      :label="items.lady_nday.text"
                      outlined
                      background-color="white"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </ValidationObserver>
      </template>
    </only-site-card>
  </div>
</template>

<script>
import {
  set,
  ref,
  reactive,
  toRefs,
  defineComponent,
} from "@vue/composition-api";
import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import utilFunc from "@/common/utilFunc.js";

export default defineComponent({
  props: ["com_data"],
  setup(props, ctx) {
    const refCommentByCastCkeditor = ref(null);
    const refCommentByShopCkeditor = ref(null);
    const CastPropsRepository = repositoryFactory.get("castProps");
    const state = reactive({
      errorElement: null,
      cast_prop: {},
      // 読み込み時データ保持(保存前比較用)
      keepData: {
        cast_prop: {},
      },
    });

    const rookieItem = [
      { value: "1", text: "新人" },
      { value: "2", text: "キャリア" },
    ];

    const items = {
      "lady_lmsg": {
        text: "女性からのメッセージ",
        rules: "",
        counter: "",
      },
      "lady_mesg": {
        text: "店舗からのメッセージ",
        rules: "",
        counter: "",
      },
      "lady_rookie": {
        text: "新人属性",
        rules: "required",
        counter: "",
      },
      "lady_nday": {
        text: "入店日",
        rules: "date_format:yyyy-MM-dd",
        counter: "",
      },
    };

    const init = async () => {
      // cast_propにキーを設定
      for (let key in items) {
        set(state.cast_prop, key, null);
        if (key == "lady_rookie") {
          // 新人属性に初期値を設定
          set(state.cast_prop, key, "1");
        }
      }
      Object.keys(props.com_data.cast_prop).forEach(function (key) {
        set(state.cast_prop, key, props.com_data.cast_prop[key]);
      });
      // 読み込み時のデータを保持(保存前に比較するため)
      state.keepData.cast_prop = Object.assign({}, state.cast_prop);
    };

    init();

    // チェック処理
    const checkValid = async () => {
      // 事前チェック処理
      state.errorElement = null;
      const isValid = await ctx.refs.observer.validate();
      if (!isValid) {
        let item_name = "";
        Object.keys(ctx.refs.observer.errors).forEach(function (key) {
          if (ctx.refs.observer.errors[key].length > 0) {
            item_name = key;
            return;
          }
        });
        const errorKey = utilFunc.getKeyByItemText(items, item_name);
        state.errorElement = ctx.refs[errorKey].$el;
      }
      return isValid;
    };

    const setErrorFocus = async () => {
      setTimeout(() => {
        state.errorElement.scrollIntoView({
          block: "center",
        });
      }, 300);
    };

    const save = async () => {
      // 更新前と比べて差分があれば更新処理
      for (let key in state.cast_prop) {
        if (key == "lady_lmsg") {
          set(state.cast_prop, key, refCommentByCastCkeditor.value.editorData);
        }
        if (key == "lady_mesg") {
          set(state.cast_prop, key, refCommentByShopCkeditor.value.editorData);
        }
      }
      if (!utilFunc.compareObject(state.keepData.cast_prop, state.cast_prop)) {
        const prop_param = [];
        for (let key in state.cast_prop) {
          prop_param.push({
            cast_id: props.com_data.cast_id,
            key: props.com_data.prefix + "__" + key,
            value: state.cast_prop[key] ?? "",
          });
        }
        await CastPropsRepository.save_all({ cast_prop: prop_param })
          .then((response) => {
            if (response.data) {
              // データ追加OK
            }
          })
          .catch((error) => {
            throw (
              "ERROR:cast_manager_wordpress.vue/save CastPropsRepository.save_all (" +
              error +
              ")"
            );
          });
      }
      return true;
    };

    const onHtmlClear = (mode) => {
      let mode_label = "";
      if (mode == "shop") {
        mode_label = "店舗からのメッセージ";
      } else {
        mode_label = "女性からのメッセージ";
      }
      if (
        !window.confirm(
          mode_label + "のHTMLに入力された内容をクリアします。よろしいですか？"
        )
      ) {
        return;
      }
      if (mode == "shop") {
        state.cast_prop.lady_mesg = "";
        if (refCommentByShopCkeditor.value) {
          refCommentByShopCkeditor.value.editorData = "";
        }
      } else {
        state.cast_prop.lady_lmsg = "";
        if (refCommentByCastCkeditor.value) {
          refCommentByCastCkeditor.value.editorData = "";
        }
      }
    };

    return {
      props,
      setting,
      rookieItem,
      items,
      ...toRefs(state),
      checkValid,
      setErrorFocus,
      save,
      refCommentByShopCkeditor,
      refCommentByCastCkeditor,
      onHtmlClear,
    };
  },
});
</script>

<style scoped></style>
