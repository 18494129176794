<template>
  <div>
    <only-site-card :com_data="com_data">
      <template v-slot:item_edit>
        <ValidationObserver ref="observer">
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="2">
                <span class="font-weight-bold">メッセージ</span>
                <p class="red--text mini">
                  未入力の場合、コメントタブの「<strong>お店のコメント</strong>」の内容で更新します。
                </p>
              </v-col>
              <v-col cols="12" sm="10">
                <v-row>
                  <v-col cols="12">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      :name="items.girl_comment.text"
                      :rules="items.girl_comment.rules"
                    >
                      <v-textarea
                        ref="girl_comment"
                        v-model="cast_prop.girl_comment"
                        name="girl_comment"
                        :error-messages="errors"
                        :success="valid"
                        :label="items.girl_comment.text"
                        rows="8"
                        outlined
                        :counter="items.girl_comment.counter"
                        background-color="white"
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mt-4 mb-10"></v-divider>
            <v-row>
              <v-col cols="12" sm="2">
                <span class="font-weight-bold">セラピストからのメッセージ</span>
                <p class="red--text mini">
                  未入力の場合、コメントタブの「<strong>セラピストコメント</strong>」の内容で更新します。
                </p>
              </v-col>
              <v-col cols="12" sm="10">
                <v-row>
                  <v-col cols="12">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      :name="items.girl_comment_girl.text"
                      :rules="items.girl_comment_girl.rules"
                    >
                      <v-textarea
                        ref="girl_comment_girl"
                        v-model="cast_prop.girl_comment_girl"
                        name="girl_comment_girl"
                        :error-messages="errors"
                        :success="valid"
                        :label="items.girl_comment_girl.text"
                        rows="8"
                        outlined
                        :counter="items.girl_comment_girl.counter"
                        background-color="white"
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mt-4 mb-10"></v-divider>
            <v-row>
              <v-col cols="12" sm="2"
                ><span class="font-weight-bold">アイコン表示</span></v-col
              >
              <v-col cols="12" sm="10">
                <v-row>
                  <v-col cols="6" sm="4" md="2">
                    <v-select
                      ref="girl_mark"
                      v-model="cast_prop.girl_mark"
                      name="girl_mark"
                      :items="girlMarkItem"
                      :no-data-text="items.girl_mark.text"
                      :label="items.girl_mark.text"
                      persistent-hint
                      success
                      outlined
                    ></v-select>
                  </v-col>
                  <v-col cols="6" sm="8" md="6">
                    <ValidationProvider
                      ref="girl_mark_text_provider"
                      v-slot="{ errors, valid }"
                      :name="items.girl_mark_text.text"
                      :rules="items.girl_mark_text.rules"
                      :disabled="cast_prop.girl_mark != '4'"
                    >
                      <v-text-field
                        ref="girl_mark_text"
                        v-model="cast_prop.girl_mark_text"
                        name="girl_mark_text"
                        :error-messages="errors"
                        :success="valid"
                        :label="items.girl_mark_text.text"
                        persistent-hint
                        outlined
                        :counter="items.girl_mark_text.counter"
                        v-show="cast_prop.girl_mark == '4'"
                        background-color="white"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mt-4 mb-10"></v-divider>
            <v-row>
              <v-col cols="12" sm="2"
                ><span class="font-weight-bold"
                  >外部リンク（1）Twitter</span
                ></v-col
              >
              <v-col cols="12" sm="10">
                <v-row>
                  <v-col cols="12" md="6">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      :name="items.girl_twitter_id.text"
                      :rules="items.girl_twitter_id.rules"
                    >
                      <v-text-field
                        ref="girl_twitter_id"
                        v-model="cast_prop.girl_twitter_id"
                        name="girl_twitter_id"
                        :error-messages="errors"
                        :success="valid"
                        :label="items.girl_twitter_id.text"
                        persistent-hint
                        outlined
                        prefix="https://twitter.com/"
                        suffix="/"
                        hint="ユーザー名の@を除いた半角英数字と(_)5~15字"
                        :counter="items.girl_twitter_id.counter"
                        background-color="white"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mt-4 mb-10"></v-divider>
            <v-row>
              <v-col cols="12" sm="2"
                ><span class="font-weight-bold"
                  >外部リンク（2）Instagram</span
                ></v-col
              >
              <v-col cols="12" sm="10">
                <v-row>
                  <v-col cols="12" md="6">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      :name="items.girl_insta_id.text"
                      :rules="items.girl_insta_id.rules"
                    >
                      <v-text-field
                        ref="girl_insta_id"
                        v-model="cast_prop.girl_insta_id"
                        name="girl_insta_id"
                        :error-messages="errors"
                        :success="valid"
                        :label="items.girl_insta_id.text"
                        persistent-hint
                        outlined
                        prefix="https://www.instagram.com/"
                        suffix="/"
                        hint="インスタグラムのユーザーネーム"
                        :counter="items.girl_insta_id.counter"
                        background-color="white"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mt-4 mb-10"></v-divider>
            <v-row>
              <v-col cols="12" sm="2"
                ><span class="font-weight-bold">外部リンク（3）</span></v-col
              >
              <v-col cols="12" sm="10">
                <v-row>
                  <v-col cols="6" sm="4" md="2">
                    <v-select
                      ref="girl_etc_type"
                      v-model="cast_prop.girl_etc_type"
                      name="girl_etc_type"
                      :items="girlEtcTypeItem"
                      :no-data-text="items.girl_etc_type.text"
                      :label="items.girl_etc_type.text"
                      persistent-hint
                      success
                      outlined
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="8" md="6">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      :name="items.girl_etc_link.text"
                      :rules="items.girl_etc_link.rules"
                    >
                      <v-text-field
                        ref="girl_etc_link"
                        v-model="cast_prop.girl_etc_link"
                        name="girl_etc_link"
                        :error-messages="errors"
                        :success="valid"
                        :label="items.girl_etc_link.text"
                        persistent-hint
                        outlined
                        :counter="items.girl_etc_link.counter"
                        background-color="white"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </ValidationObserver>
      </template>
    </only-site-card>
  </div>
</template>

<script>
import {
  set,
  ref,
  reactive,
  toRefs,
  defineComponent,
} from "@vue/composition-api";
import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import utilFunc from "@/common/utilFunc.js";
import selectItem from "@/common/selectItem.js";

export default defineComponent({
  props: ["com_data"],
  setup(props, ctx) {
    const CastPropsRepository = repositoryFactory.get("castProps");
    const girl_mark_text_provider = ref(null);
    const state = reactive({
      errorElement: null,
      cast_prop: {},
      // 読み込み時データ保持(保存前比較用)
      keepData: {
        cast_prop: {},
      },
    });

    const girlMarkItem = [
      { value: "0", text: "表示しない" },
      { value: "1", text: "体験入店" },
      { value: "2", text: "新人" },
      { value: "3", text: "写真更新" },
      { value: "4", text: "自由入力" },
    ];

    const girlEtcTypeItem = [
      { value: "0", text: "日記系" },
      { value: "1", text: "LINE系" },
      { value: "2", text: "ハート系" },
    ];

    const items = {
      "girl_comment": {
        text: "メッセージ",
        rules: "max:1500",
        counter: "1500",
      },
      "girl_comment_girl": {
        text: "セラピストからのメッセージ",
        rules: "max:1500",
        counter: "1500",
      },
      "girl_mark": {
        text: "アイコン表示",
        rules: "required",
        counter: "",
      },
      "girl_mark_text": {
        text: "自由入力",
        rules: "max:8",
        counter: "8",
      },
      "girl_twitter_id": {
        text: "",
        rules: "min:5|max:15|twitter",
        counter: "15",
      },
      "girl_insta_id": {
        text: "",
        rules: "max:30|instagram",
        counter: "30",
      },
      "girl_etc_type": {
        text: "アイコン",
        rules: "required",
        counter: "",
      },
      "girl_etc_link": {
        text: "URL",
        rules: "max:100|url",
        counter: "100",
      },
    };

    const init = async () => {
      // cast_propにキーを設定
      for (let key in items) {
        set(state.cast_prop, key, null);
      }
      Object.keys(props.com_data.cast_prop).forEach(function (key) {
        set(state.cast_prop, key, props.com_data.cast_prop[key]);
      });
      // 読み込み時のデータを保持(保存前に比較するため)
      state.keepData.cast_prop = Object.assign({}, state.cast_prop);
    };

    init();

    // チェック処理
    const checkValid = async () => {
      // 事前チェック処理
      state.errorElement = null;
      const isValid = await ctx.refs.observer.validate();
      if (!isValid) {
        let item_name = "";
        Object.keys(ctx.refs.observer.errors).forEach(function (key) {
          if (ctx.refs.observer.errors[key].length > 0) {
            item_name = key;
            return;
          }
        });
        const errorKey = utilFunc.getKeyByItemText(items, item_name);
        state.errorElement = ctx.refs[errorKey].$el;
      }
      return isValid;
    };

    const setErrorFocus = async () => {
      setTimeout(() => {
        state.errorElement.scrollIntoView({
          block: "center",
        });
      }, 300);
    };

    const save = async () => {
      // 更新前と比べて差分があれば更新処理
      if (!utilFunc.compareObject(state.keepData.cast_prop, state.cast_prop)) {
        const prop_param = [];
        for (let key in state.cast_prop) {
          prop_param.push({
            cast_id: props.com_data.cast_id,
            key: props.com_data.prefix + "__" + key,
            value: state.cast_prop[key] ?? "",
          });
        }
        await CastPropsRepository.save_all({ cast_prop: prop_param })
          .then((response) => {
            if (response.data) {
              // データ追加OK
            }
          })
          .catch((error) => {
            throw (
              "ERROR:cast_manager_wordpress.vue/save CastPropsRepository.save_all (" +
              error +
              ")"
            );
          });
      }
      return true;
    };

    return {
      props,
      girlMarkItem,
      girlEtcTypeItem,
      setting,
      items,
      ...toRefs(state),
      checkValid,
      setErrorFocus,
      save,
      girl_mark_text_provider,
    };
  },
});
</script>

<style scoped></style>
