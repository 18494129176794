<template>
  <div>
    <only-site-card :com_data="com_data">
      <template v-slot:item_edit>
        <ValidationObserver ref="observer">
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="2">
                <span class="font-weight-bold">女の子からのメッセージ</span>
                <p class="red--text mini">
                  未入力の場合、コメントタブの「<strong>セラピストコメント</strong>」の内容で更新します。
                </p>
              </v-col>
              <v-col cols="12" sm="10">
                <v-row>
                  <v-col cols="12">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      :name="items.girl_comments.text"
                      :rules="items.girl_comments.rules"
                    >
                      <v-textarea
                        ref="girl_comments"
                        v-model="cast_prop.girl_comments"
                        name="girl_comments"
                        :error-messages="errors"
                        :success="valid"
                        :label="items.girl_comments.text"
                        :placeholder="`女の子からのメッセージ`"
                        rows="6"
                        outlined
                        :counter="items.girl_comments.counter"
                        background-color="white"
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mt-4 mb-8"></v-divider>
            <v-row>
              <v-col cols="12" sm="2"
                ><span class="font-weight-bold"
                  >お店からのメッセージ タイトル</span
                ></v-col
              >
              <v-col cols="12" sm="10">
                <v-row>
                  <v-col cols="12">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      :name="items.title.text"
                      :rules="items.title.rules"
                    >
                      <v-text-field
                        ref="title"
                        v-model="cast_prop.title"
                        name="title"
                        :error-messages="errors"
                        :success="valid"
                        :label="items.title.text"
                        outlined
                        :counter="items.title.counter"
                        background-color="white"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="2">
                <span class="font-weight-bold">お店からのメッセージ</span>
                <p class="red--text mini">
                  未入力の場合、コメントタブの「<strong>お店コメント</strong>」の内容で更新します。
                </p>
              </v-col>
              <v-col cols="12" sm="10">
                <v-row>
                  <v-col cols="12">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      :name="items.comments.text"
                      :rules="items.comments.rules"
                    >
                      <v-textarea
                        ref="comments"
                        v-model="cast_prop.comments"
                        name="comments"
                        :error-messages="errors"
                        :success="valid"
                        :label="items.comments.text"
                        :placeholder="`お店からのメッセージ`"
                        rows="6"
                        outlined
                        :counter="items.comments.counter"
                        background-color="white"
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mt-4 mb-8"></v-divider>
            <v-row>
              <v-col cols="12" sm="2"
                ><span class="font-weight-bold">可能オプション</span></v-col
              >
              <v-col cols="12" sm="10">
                <v-row>
                  <v-col cols="12">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      :name="items.options.text"
                      :rules="items.options.rules"
                    >
                      <v-textarea
                        ref="options"
                        v-model="cast_prop.options"
                        name="options"
                        :error-messages="errors"
                        :success="valid"
                        :label="items.options.text"
                        hint="可能オプション(△は要確認)"
                        persistent-hint
                        rows="6"
                        outlined
                        :counter="items.options.counter"
                        background-color="white"
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mt-4 mb-8"></v-divider>
            <v-row>
              <v-col cols="12" sm="2"
                ><span class="font-weight-bold"
                  >女の子ランキング<br />優先タグ選択</span
                ></v-col
              >
              <v-col cols="12" sm="10">
                女の子ランキング優先タグ選択を{{
                  items.p_genre.counter
                }}個以内で選択してください。
                <ValidationProvider
                  v-slot="{ errors, valid }"
                  :name="items.p_genre.text"
                  :rules="items.p_genre.rules"
                >
                  <div v-if="!valid" class="red--text">
                    {{ items.p_genre.counter }}個までしか選択出来ません
                  </div>
                  <v-row class="mt-2 mb-2">
                    <v-col
                      v-for="p_genre in p_genre_type"
                      :key="p_genre.label"
                      cols="4"
                      sm="3"
                      md="2"
                    >
                      <v-checkbox
                        class="mt-1"
                        ref="p_genre"
                        v-model="p_genre_selected"
                        name="p_genre"
                        :label="p_genre.label"
                        :value="p_genre.value"
                        :success="valid"
                        :error="!valid"
                        hide-details
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-divider class="mt-4 mb-8"></v-divider>
            <v-row>
              <v-col cols="12" sm="2"
                ><div class="font-weight-bold">ジャンル選択</div>
                <div class="item-req">
                  <div>必須項目</div>
                </div>
              </v-col>
              <v-col cols="12" sm="10">
                ジャンルを{{ items.genre.counter }}個以内で選択してください。
                <div class="pa-1">
                  <div class="pa-2 selected_genre">
                    <div class="mb-1">
                      選択中のジャンル※ドラッグで並び替えできます。上位3個は媒体にて大きなアイコンで表示されます
                    </div>
                    <draggable
                      v-model="genre_selected_label"
                      v-bind="dragOptions"
                      handle=".chip_label"
                      :force-fallback="true"
                    >
                      <v-chip
                        class="chip_label mr-1 mb-1 white--text font-weight-bold success"
                        style="cursor: pointer"
                        v-for="(value, key) in genre_selected_label"
                        :key="key"
                        >{{ value }}</v-chip
                      >
                    </draggable>
                  </div>
                </div>
                <ValidationProvider
                  v-slot="{ errors, valid }"
                  :name="items.genre.text"
                  :rules="items.genre.rules"
                >
                  <div v-if="!valid" class="red--text">
                    <span v-if="genre_selected_label.length == 0"
                      >必須入力です。1個以上、選択してください。</span
                    >
                    <span
                      v-if="genre_selected_label.length > items.genre.counter"
                      >最大{{ items.genre.counter }}個まで選択できます。</span
                    >
                  </div>
                  <div v-for="(value, key) in genre_type" :key="key">
                    <v-divider class="mt-2 mb-4"></v-divider>
                    <div>{{ value.category }}</div>
                    <v-row class="mt-2 mb-2">
                      <v-col
                        v-for="genre in value.items"
                        :key="genre.label"
                        cols="4"
                        sm="3"
                        md="2"
                      >
                        <v-checkbox
                          class="mt-1"
                          ref="genre"
                          v-model="genre_selected"
                          name="genre"
                          :label="genre.label"
                          :value="genre.value"
                          :success="valid"
                          :error="!valid"
                          hide-details
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </div>
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-container>
        </ValidationObserver>
      </template>
    </only-site-card>
  </div>
</template>

<script>
import {
  set,
  ref,
  watch,
  reactive,
  toRefs,
  defineComponent,
} from "@vue/composition-api";
import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";
import draggable from "vuedraggable";
import setting from "@/common/setting.js";
import utilFunc from "@/common/utilFunc.js";

export default defineComponent({
  components: {
    draggable,
  },
  props: ["com_data"],
  setup(props, ctx) {
    const CastPropsRepository = repositoryFactory.get("castProps");
    const state = reactive({
      errorElement: null,
      cast_prop: {},
      // 読み込み時データ保持(保存前比較用)
      keepData: {
        cast_prop: {},
      },
      p_genre_selected: [],
      p_genre_type: {
        "p_genre-id-1": { label: "no1", value: "1" },
        "p_genre-id-24": { label: "av女優", value: "24" },
        "p_genre-id-75": { label: "アジアン", value: "75" },
        "p_genre-id-76": { label: "ﾌﾞﾛﾝﾄﾞ・金髪", value: "76" },
        "p_genre-id-61": { label: "ニューハーフ", value: "61" },
        "p_genre-id-59": { label: "顔出し", value: "59" },
        "p_genre-id-62": { label: "熟女系", value: "62" },
        "p_genre-id-11": { label: "ロリ系", value: "11" },
        "p_genre-id-8": { label: "お姉さん系", value: "8" },
        "p_genre-id-19": { label: "ぽっちゃり", value: "19" },
        "p_genre-id-28": { label: "巨乳", value: "28" },
        "p_genre-id-17": { label: "スレンダー", value: "17" },
        "p_genre-id-42": { label: "スタイル抜群", value: "42" },
        "p_genre-id-58": { label: "高身長", value: "58" },
        "p_genre-id-65": { label: "低身長", value: "65" },
        "p_genre-id-20": { label: "人妻系", value: "20" },
        "p_genre-id-25": { label: "テクニシャン", value: "25" },
        "p_genre-id-60": { label: "ドM(ドエム)", value: "60" },
        "p_genre-id-68": { label: "ドS(ドエス)", value: "68" },
        "p_genre-id-53": { label: "痴女", value: "53" },
      },
      genre_selected: [],
      genre_selected_label: [],
      genre_type: {
        "group1": {
          "category": "ステータス",
          "items": {
            "genre-id-1": { label: "no1", value: "1" },
            "genre-id-2": { label: "no2", value: "2" },
            "genre-id-3": { label: "no3", value: "3" },
            "genre-id-4": { label: "プレミア", value: "4" },
            "genre-id-5": { label: "店長オススメ", value: "5" },
            "genre-id-24": { label: "av女優", value: "24" },
            "genre-id-38": { label: "素人", value: "38" },
            "genre-id-40": { label: "未経験", value: "40" },
            "genre-id-46": { label: "禁煙", value: "46" },
            "genre-id-51": { label: "要予約", value: "51" },
            "genre-id-59": { label: "顔出し", value: "59" },
            "genre-id-75": { label: "アジアン", value: "75" },
            "genre-id-76": { label: "ﾌﾞﾛﾝﾄﾞ・金髪", value: "76" },
            "genre-id-61": { label: "ニューハーフ", value: "61" },
          },
        },
        "group2": {
          "category": "ルックス",
          "items": {
            "genre-id-7": { label: "アイドル系", value: "7" },
            "genre-id-8": { label: "お姉さん系", value: "8" },
            "genre-id-62": { label: "熟女系", value: "62" },
            "genre-id-9": { label: "お嬢様", value: "9" },
            "genre-id-10": { label: "可愛い系", value: "10" },
            "genre-id-11": { label: "ロリ系", value: "11" },
            "genre-id-14": { label: "ギャル系", value: "14" },
            "genre-id-15": { label: "キレカワ", value: "15" },
            "genre-id-21": { label: "美少女系", value: "21" },
            "genre-id-23": { label: "綺麗系", value: "23" },
            "genre-id-56": { label: "ハーフ", value: "56" },
          },
        },
        "group3": {
          "category": "スタイル",
          "items": {
            "genre-id-16": { label: "グラマー", value: "16" },
            "genre-id-17": { label: "スレンダー", value: "17" },
            "genre-id-19": { label: "ぽっちゃり", value: "19" },
            "genre-id-26": { label: "パイパン", value: "26" },
            "genre-id-27": { label: "ミニマム", value: "27" },
            "genre-id-28": { label: "巨乳", value: "28" },
            "genre-id-29": { label: "爆乳", value: "29" },
            "genre-id-63": { label: "貧乳", value: "63" },
            "genre-id-30": { label: "美乳", value: "30" },
            "genre-id-64": { label: "巨尻", value: "64" },
            "genre-id-31": { label: "美尻", value: "31" },
            "genre-id-33": { label: "美肌", value: "33" },
            "genre-id-34": { label: "美脚", value: "34" },
            "genre-id-36": { label: "色白", value: "36" },
            "genre-id-42": { label: "スタイル抜群", value: "42" },
            "genre-id-45": { label: "モデル系", value: "45" },
            "genre-id-58": { label: "高身長", value: "58" },
            "genre-id-65": { label: "低身長", value: "65" },
            "genre-id-66": { label: "妊婦プレイ", value: "66" },
            "genre-id-67": { label: "ﾀﾄｩｰ・刺青", value: "67" },
          },
        },
        "group4": {
          "category": "雰囲気",
          "items": {
            "genre-id-6": { label: "OL系", value: "6" },
            "genre-id-12": { label: "女子大生", value: "12" },
            "genre-id-13": { label: "キャバ系", value: "13" },
            "genre-id-18": { label: "セクシー系", value: "18" },
            "genre-id-20": { label: "人妻系", value: "20" },
            "genre-id-22": { label: "癒し系", value: "22" },
            "genre-id-49": { label: "清楚", value: "49" },
            "genre-id-50": { label: "萌え系", value: "50" },
            "genre-id-52": { label: "おっとり", value: "52" },
            "genre-id-54": { label: "天然", value: "54" },
          },
        },
        "group5": {
          "category": "プレイ関連",
          "items": {
            "genre-id-25": { label: "テクニシャン", value: "25" },
            "genre-id-35": { label: "敏感", value: "35" },
            "genre-id-41": { label: "サービス抜群", value: "41" },
            "genre-id-43": { label: "愛嬌抜群", value: "43" },
            "genre-id-44": { label: "ｲﾁｬｲﾁｬ好き", value: "44" },
            "genre-id-47": { label: "攻め好き", value: "47" },
            "genre-id-48": { label: "受身好き", value: "48" },
            "genre-id-53": { label: "痴女", value: "53" },
            "genre-id-55": { label: "濃厚サービス", value: "55" },
            "genre-id-57": { label: "潮吹き", value: "57" },
            "genre-id-60": { label: "ドM(ドエム)", value: "60" },
            "genre-id-68": { label: "ドS(ドエス)", value: "68" },
            "genre-id-69": { label: "母乳プレイ", value: "69" },
            "genre-id-70": { label: "パイズリ", value: "70" },
            "genre-id-71": { label: "ＡＦ可", value: "71" },
            "genre-id-72": { label: "イラマチオ可", value: "72" },
            "genre-id-73": { label: "3Ｐ可", value: "73" },
            "genre-id-74": { label: "ごっくん", value: "74" },
          },
        },
      },
    });

    const items = {
      "girl_comments": {
        text: "女の子からのメッセージ",
        rules: "max:200",
        counter: "200",
      },
      "title": {
        text: "タイトル",
        rules: "max:80",
        counter: "80",
      },
      "comments": {
        text: "お店からのメッセージ",
        rules: "max:2000",
        counter: "2000",
      },
      "options": {
        text: "可能オプション",
        rules: "max:145",
        counter: "145",
      },
      "p_genre": {
        text: "女の子ランキング優先タグ",
        rules: "check_max:3",
        counter: "3",
      },
      "genre": {
        text: "ジャンル選択",
        rules: "required|check_max:19",
        counter: "19",
      },
      "genre2": {
        text: "ジャンル選択(表示順)",
      },
    };

    const init = async () => {
      // cast_propにキーを設定
      for (let key in items) {
        set(state.cast_prop, key, null);
      }
      Object.keys(props.com_data.cast_prop).forEach(function (key) {
        set(state.cast_prop, key, props.com_data.cast_prop[key]);
      });
      // 読み込み時のデータを保持(保存前に比較するため)
      state.keepData.cast_prop = Object.assign({}, state.cast_prop);
      // console.log(state.keepData.cast_prop["genre"]);

      // 各ジャンルをjsonから配列に変換しておく
      if (state.cast_prop.p_genre) {
        state.p_genre_selected = JSON.parse(state.cast_prop.p_genre);
      }
      if (state.cast_prop.genre2) {
        let label = "";
        state.genre_selected = JSON.parse(state.cast_prop.genre2);
        Object.keys(state.genre_selected).forEach(function (labelKey) {
          Object.keys(state.genre_type).forEach(function (key) {
            Object.keys(state.genre_type[key]["items"]).forEach(function (
              genre
            ) {
              if (
                state.genre_type[key]["items"][genre].value ==
                state.genre_selected[labelKey]
              ) {
                label = state.genre_type[key]["items"][genre].label;
              }
            });
          });
          state.genre_selected_label.push(label);
        });
      }
    };

    init();

    // チェック処理
    const checkValid = async () => {
      // 事前チェック処理
      state.errorElement = null;
      const isValid = await ctx.refs.observer.validate();
      if (!isValid) {
        let item_name = "";
        Object.keys(ctx.refs.observer.errors).forEach(function (key) {
          if (ctx.refs.observer.errors[key].length > 0) {
            item_name = key;
            return;
          }
        });
        const errorKey = utilFunc.getKeyByItemText(items, item_name);
        if (errorKey == "p_genre" || errorKey == "genre") {
          state.errorElement = ctx.refs[errorKey][0].$el;
        } else {
          state.errorElement = ctx.refs[errorKey].$el;
        }
      }
      return isValid;
    };

    const setErrorFocus = async () => {
      setTimeout(() => {
        state.errorElement.scrollIntoView({
          block: "center",
        });
      }, 300);
    };

    const save = async () => {
      // 更新前と比べて差分があれば更新処理
      for (let key in state.cast_prop) {
        if (key == "p_genre") {
          set(
            state.cast_prop,
            key,
            state.p_genre_selected.length == 0
              ? ""
              : JSON.stringify(state.p_genre_selected)
          );
        }
        if (key == "genre" || key == "genre2") {
          // genre  => チェックされた項目を昇順に並び替えて格納
          // genre2 => チェックされた項目を画面の並びにしたがってそのまま格納
          const tmp_genre_selected = [];
          for (let i = 0; i < state.genre_selected_label.length; i++) {
            Object.keys(state.genre_type).forEach(function (group) {
              Object.keys(state.genre_type[group]["items"]).forEach(function (
                genre
              ) {
                if (
                  state.genre_type[group]["items"][genre].label ==
                  state.genre_selected_label[i]
                ) {
                  tmp_genre_selected.push(
                    state.genre_type[group]["items"][genre].value
                  );
                }
              });
            });
          }
          if (key == "genre") {
            tmp_genre_selected.sort(
              (first, second) => Number(first) - Number(second)
            );
          }
          set(
            state.cast_prop,
            key,
            tmp_genre_selected.length == 0
              ? ""
              : JSON.stringify(tmp_genre_selected)
          );
        }
      }
      // console.log("cast_prop", state.cast_prop);
      if (!utilFunc.compareObject(state.keepData.cast_prop, state.cast_prop)) {
        const prop_param = [];
        for (let key in state.cast_prop) {
          prop_param.push({
            cast_id: props.com_data.cast_id,
            key: props.com_data.prefix + "__" + key,
            value: state.cast_prop[key] ?? "",
          });
        }
        await CastPropsRepository.save_all({ cast_prop: prop_param })
          .then((response) => {
            if (response.data) {
              // データ追加OK
            }
          })
          .catch((error) => {
            throw (
              "ERROR:cast_manager_wordpress.vue/save CastPropsRepository.save_all (" +
              error +
              ")"
            );
          });
      }
      return true;
    };

    const { genre_selected } = toRefs(state);
    watch(genre_selected, (next, prev) => {
      let diff = null;
      if (next.length < prev.length) {
        // 増えた時
        diff = prev.filter((i) => next.indexOf(i) == -1);
      } else if (next.length > prev.length) {
        // 減った時
        diff = next.filter((i) => prev.indexOf(i) == -1);
      } else {
        return;
      }
      let label = "";
      Object.keys(state.genre_type).forEach(function (key) {
        Object.keys(state.genre_type[key]["items"]).forEach(function (genre) {
          if (state.genre_type[key]["items"][genre].value == diff[0]) {
            label = state.genre_type[key]["items"][genre].label;
          }
        });
      });
      if (next.length < prev.length) {
        let index = state.genre_selected_label.indexOf(label);
        state.genre_selected_label.splice(index, 1);
      } else {
        state.genre_selected_label.push(label);
      }
    });

    return {
      dragOptions: {
        animation: 200,
        group: props.shop_site_id,
        disabled: false,
        ghostClass: "ghost",
      },
      props,
      setting,
      items,
      utilFunc,
      ...toRefs(state),
      checkValid,
      setErrorFocus,
      save,
    };
  },
});
</script>

<style scoped>
.selected_genre {
  background-color: white;
  border-radius: 10px;
}
</style>
