var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('only-site-card',{attrs:{"com_data":_vm.com_data},scopedSlots:_vm._u([{key:"item_edit",fn:function(){return [_c('ValidationObserver',{ref:"observer"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("メッセージ")]),_c('p',{staticClass:"red--text mini"},[_vm._v(" 未入力の場合、コメントタブの「"),_c('strong',[_vm._v("お店のコメント")]),_vm._v("」の内容で更新します。 ")])]),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.items.girl_comment.text,"rules":_vm.items.girl_comment.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-textarea',{ref:"girl_comment",attrs:{"name":"girl_comment","error-messages":errors,"success":valid,"label":_vm.items.girl_comment.text,"rows":"8","outlined":"","counter":_vm.items.girl_comment.counter,"background-color":"white"},model:{value:(_vm.cast_prop.girl_comment),callback:function ($$v) {_vm.$set(_vm.cast_prop, "girl_comment", $$v)},expression:"cast_prop.girl_comment"}})]}}])})],1)],1)],1)],1),_c('v-divider',{staticClass:"mt-4 mb-10"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("セラピストからのメッセージ")]),_c('p',{staticClass:"red--text mini"},[_vm._v(" 未入力の場合、コメントタブの「"),_c('strong',[_vm._v("セラピストコメント")]),_vm._v("」の内容で更新します。 ")])]),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.items.girl_comment_girl.text,"rules":_vm.items.girl_comment_girl.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-textarea',{ref:"girl_comment_girl",attrs:{"name":"girl_comment_girl","error-messages":errors,"success":valid,"label":_vm.items.girl_comment_girl.text,"rows":"8","outlined":"","counter":_vm.items.girl_comment_girl.counter,"background-color":"white"},model:{value:(_vm.cast_prop.girl_comment_girl),callback:function ($$v) {_vm.$set(_vm.cast_prop, "girl_comment_girl", $$v)},expression:"cast_prop.girl_comment_girl"}})]}}])})],1)],1)],1)],1),_c('v-divider',{staticClass:"mt-4 mb-10"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("アイコン表示")])]),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"4","md":"2"}},[_c('v-select',{ref:"girl_mark",attrs:{"name":"girl_mark","items":_vm.girlMarkItem,"no-data-text":_vm.items.girl_mark.text,"label":_vm.items.girl_mark.text,"persistent-hint":"","success":"","outlined":""},model:{value:(_vm.cast_prop.girl_mark),callback:function ($$v) {_vm.$set(_vm.cast_prop, "girl_mark", $$v)},expression:"cast_prop.girl_mark"}})],1),_c('v-col',{attrs:{"cols":"6","sm":"8","md":"6"}},[_c('ValidationProvider',{ref:"girl_mark_text_provider",attrs:{"name":_vm.items.girl_mark_text.text,"rules":_vm.items.girl_mark_text.rules,"disabled":_vm.cast_prop.girl_mark != '4'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(_vm.cast_prop.girl_mark == '4'),expression:"cast_prop.girl_mark == '4'"}],ref:"girl_mark_text",attrs:{"name":"girl_mark_text","error-messages":errors,"success":valid,"label":_vm.items.girl_mark_text.text,"persistent-hint":"","outlined":"","counter":_vm.items.girl_mark_text.counter,"background-color":"white"},model:{value:(_vm.cast_prop.girl_mark_text),callback:function ($$v) {_vm.$set(_vm.cast_prop, "girl_mark_text", $$v)},expression:"cast_prop.girl_mark_text"}})]}}])})],1)],1)],1)],1),_c('v-divider',{staticClass:"mt-4 mb-10"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("外部リンク（1）Twitter")])]),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.items.girl_twitter_id.text,"rules":_vm.items.girl_twitter_id.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{ref:"girl_twitter_id",attrs:{"name":"girl_twitter_id","error-messages":errors,"success":valid,"label":_vm.items.girl_twitter_id.text,"persistent-hint":"","outlined":"","prefix":"https://twitter.com/","suffix":"/","hint":"ユーザー名の@を除いた半角英数字と(_)5~15字","counter":_vm.items.girl_twitter_id.counter,"background-color":"white"},model:{value:(_vm.cast_prop.girl_twitter_id),callback:function ($$v) {_vm.$set(_vm.cast_prop, "girl_twitter_id", $$v)},expression:"cast_prop.girl_twitter_id"}})]}}])})],1)],1)],1)],1),_c('v-divider',{staticClass:"mt-4 mb-10"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("外部リンク（2）Instagram")])]),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.items.girl_insta_id.text,"rules":_vm.items.girl_insta_id.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{ref:"girl_insta_id",attrs:{"name":"girl_insta_id","error-messages":errors,"success":valid,"label":_vm.items.girl_insta_id.text,"persistent-hint":"","outlined":"","prefix":"https://www.instagram.com/","suffix":"/","hint":"インスタグラムのユーザーネーム","counter":_vm.items.girl_insta_id.counter,"background-color":"white"},model:{value:(_vm.cast_prop.girl_insta_id),callback:function ($$v) {_vm.$set(_vm.cast_prop, "girl_insta_id", $$v)},expression:"cast_prop.girl_insta_id"}})]}}])})],1)],1)],1)],1),_c('v-divider',{staticClass:"mt-4 mb-10"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("外部リンク（3）")])]),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"4","md":"2"}},[_c('v-select',{ref:"girl_etc_type",attrs:{"name":"girl_etc_type","items":_vm.girlEtcTypeItem,"no-data-text":_vm.items.girl_etc_type.text,"label":_vm.items.girl_etc_type.text,"persistent-hint":"","success":"","outlined":""},model:{value:(_vm.cast_prop.girl_etc_type),callback:function ($$v) {_vm.$set(_vm.cast_prop, "girl_etc_type", $$v)},expression:"cast_prop.girl_etc_type"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"8","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.items.girl_etc_link.text,"rules":_vm.items.girl_etc_link.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{ref:"girl_etc_link",attrs:{"name":"girl_etc_link","error-messages":errors,"success":valid,"label":_vm.items.girl_etc_link.text,"persistent-hint":"","outlined":"","counter":_vm.items.girl_etc_link.counter,"background-color":"white"},model:{value:(_vm.cast_prop.girl_etc_link),callback:function ($$v) {_vm.$set(_vm.cast_prop, "girl_etc_link", $$v)},expression:"cast_prop.girl_etc_link"}})]}}])})],1)],1)],1)],1)],1)],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }