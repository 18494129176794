<template>
  <div>
    <only-site-card :com_data="com_data">
      <template v-slot:item_edit>
        <ValidationObserver ref="observer">
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="2">
                <div class="font-weight-bold mb-2">セラピストコメント</div>
                <p class="red--text mini">
                  未入力の場合、コメントタブの「<strong>セラピストコメント</strong>」の内容で更新します。
                </p>
              </v-col>
              <v-col cols="12" sm="10">
                <v-row>
                  <v-col col="12">
                    <div class="mt-1 mb-1">
                      <span class="font-weight-bold text-align-middle"
                        >HTML用&nbsp;</span
                      ><v-btn
                        text
                        color="primary"
                        class="font-weight-bold pa-1 d-inline"
                        @click="onHtmlClear('cast')"
                        >HTMLをクリア</v-btn
                      >
                    </div>
                    <div class="">
                      <ckeditor-component
                        ref="refCommentByCastCkeditor"
                        :data="cast_prop.itemCommentBody"
                      ></ckeditor-component>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mt-4 mb-10"></v-divider>
            <v-row>
              <v-col cols="12" sm="2">
                <div class="font-weight-bold mb-2">店長コメント</div>
                <p class="red--text mini">
                  未入力の場合、コメントタブの「<strong>お店コメント</strong>」の内容で更新します。
                </p>
              </v-col>
              <v-col cols="12" sm="10">
                <v-row>
                  <v-col col="12">
                    <div class="mt-1 mb-1">
                      <span class="font-weight-bold text-align-middle"
                        >HTML用&nbsp;</span
                      ><v-btn
                        text
                        color="primary"
                        class="font-weight-bold pa-1 d-inline"
                        @click="onHtmlClear('shop')"
                        >HTMLをクリア</v-btn
                      >
                    </div>
                    <div class="">
                      <ckeditor-component
                        ref="refCommentByShopCkeditor"
                        :data="cast_prop.managerCommentBody"
                      ></ckeditor-component>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mt-4 mb-10"></v-divider>
            <v-row>
              <v-col cols="12" sm="2"
                ><span class="font-weight-bold">ピックアップ</span></v-col
              >
              <v-col cols="12" sm="10">
                <v-row>
                  <v-col cols="12">
                    <v-switch
                      class="mt-1"
                      ref="isPickup"
                      v-model="cast_prop.isPickup"
                      name="isPickup"
                      :label="items.isPickup.text"
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mt-4 mb-10"></v-divider>
            <v-row>
              <v-col cols="12" sm="2"
                ><span class="font-weight-bold">体重</span></v-col
              >
              <v-col cols="12" sm="10">
                <v-row>
                  <v-col cols="6" sm="3" md="2">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      :name="items.weight.text"
                      :rules="items.weight.rules"
                    >
                      <v-text-field
                        ref="weight"
                        v-model="cast_prop.weight"
                        name="weight"
                        :error-messages="errors"
                        :success="valid"
                        :label="items.weight.text"
                        suffix="kg"
                        outlined
                        :counter="items.weight.counter"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mt-4 mb-10"></v-divider>
            <v-row>
              <v-col cols="12" sm="2"
                ><span class="font-weight-bold"
                  >入店日/新人チェック</span
                ></v-col
              >
              <v-col cols="12" sm="10">
                <v-row>
                  <v-col cols="4" sm="3" md="2">
                    <v-select
                      ref="joinShopYear"
                      v-model="cast_prop.joinShopYear"
                      name="joinShopYear"
                      :items="joinShopYearItem"
                      :label="items.joinShopYear.text"
                      success
                      outlined
                      background-color="white"
                      hide-details
                    ></v-select>
                  </v-col>
                  <v-col cols="4" sm="3" md="2">
                    <v-select
                      ref="joinShopMonth"
                      v-model="cast_prop.joinShopMonth"
                      name="joinShopMonth"
                      :items="joinShopMonthItem"
                      :label="items.joinShopMonth.text"
                      success
                      outlined
                      background-color="white"
                      hide-details
                    ></v-select>
                  </v-col>
                  <v-col cols="4" sm="3" md="2">
                    <v-select
                      ref="joinShopDay"
                      v-model="cast_prop.joinShopDay"
                      name="joinShopDay"
                      :items="joinShopDayItem"
                      :label="items.joinShopDay.text"
                      success
                      outlined
                      background-color="white"
                      hide-details
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-switch
                      class="mt-1"
                      ref="isNew"
                      v-model="cast_prop.isNew"
                      name="isNew"
                      :label="items.isNew.text"
                    ></v-switch>
                  </v-col>
                  <v-col cols="6" sm="4" md="3">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      :name="items.isNewEndDay.text"
                      :rules="items.isNewEndDay.rules"
                      :disabled="!cast_prop.isNew"
                    >
                      <v-text-field
                        ref="isNewEndDay"
                        v-model="cast_prop.isNewEndDay"
                        name="isNewEndDay"
                        :error-messages="errors"
                        :success="valid"
                        :label="items.isNewEndDay.text"
                        type="date"
                        outlined
                        background-color="white"
                        v-show="cast_prop.isNew"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mt-4 mb-10"></v-divider>
            <v-row>
              <v-col cols="12" sm="2"
                ><span class="font-weight-bold">スタイル/タイプ</span></v-col
              >
              <v-col cols="12" sm="10">
                <v-row>
                  <v-col cols="6" sm="4" md="3">
                    <v-select
                      ref="style"
                      v-model="cast_prop.style"
                      name="style"
                      :items="styleItem"
                      :no-data-text="items.style.text"
                      :label="items.style.text"
                      success
                      outlined
                      background-color="white"
                    ></v-select>
                  </v-col>
                  <v-col cols="6" sm="4" md="3">
                    <v-select
                      ref="kind"
                      v-model="cast_prop.kind"
                      name="kind"
                      :items="kindItem"
                      :no-data-text="items.kind.text"
                      :label="items.kind.text"
                      success
                      outlined
                      background-color="white"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mt-4 mb-10"></v-divider>
            <v-row>
              <v-col cols="12" sm="2"
                ><span class="font-weight-bold">ブログURL</span></v-col
              >
              <v-col cols="12" sm="10">
                <v-row>
                  <v-col cols="12">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      :name="items.blogUrl.text"
                      :rules="items.blogUrl.rules"
                    >
                      <v-text-field
                        ref="blogUrl"
                        v-model="cast_prop.blogUrl"
                        name="blogUrl"
                        :error-messages="errors"
                        :success="valid"
                        :label="items.blogUrl.text"
                        persistent-hint
                        outlined
                        hint="「https://」から入力して下さい"
                        :maxlength="items.blogUrl.counter"
                        :counter="items.blogUrl.counter"
                        background-color="white"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      :name="items.smartPhoneBlogUrl.text"
                      :rules="items.smartPhoneBlogUrl.rules"
                    >
                      <v-text-field
                        ref="smartPhoneBlogUrl"
                        v-model="cast_prop.smartPhoneBlogUrl"
                        name="smartPhoneBlogUrl"
                        :error-messages="errors"
                        :success="valid"
                        :label="items.smartPhoneBlogUrl.text"
                        persistent-hint
                        outlined
                        hint="「https://」から入力して下さい"
                        :maxlength="items.smartPhoneBlogUrl.counter"
                        :counter="items.smartPhoneBlogUrl.counter"
                        background-color="white"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </ValidationObserver>
      </template>
    </only-site-card>
  </div>
</template>

<script>
import {
  set,
  ref,
  reactive,
  toRefs,
  defineComponent,
} from "@vue/composition-api";
import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import utilFunc from "@/common/utilFunc.js";
import selectItem from "@/common/selectItem.js";

export default defineComponent({
  props: ["com_data"],
  setup(props, ctx) {
    const CastPropsRepository = repositoryFactory.get("castProps");
    const refCommentByShopCkeditor = ref(null);
    const refCommentByCastCkeditor = ref(null);
    const state = reactive({
      errorElement: null,
      cast_prop: {},
      // 読み込み時データ保持(保存前比較用)
      keepData: {
        cast_prop: {},
      },
      today: null,
      nextMonthDay: null,
    });

    const joinShopYearItem = [{ value: null, text: "--" }];

    const joinShopMonthItem = [
      { value: null, text: "--" },
      { value: 1, text: "1月" },
      { value: 2, text: "2月" },
      { value: 3, text: "3月" },
      { value: 4, text: "4月" },
      { value: 5, text: "5月" },
      { value: 6, text: "6月" },
      { value: 7, text: "7月" },
      { value: 8, text: "8月" },
      { value: 9, text: "9月" },
      { value: 10, text: "10月" },
      { value: 11, text: "11月" },
      { value: 12, text: "12月" },
    ];

    const joinShopDayItem = [
      { value: null, text: "--" },
      { value: 1, text: "1日" },
      { value: 2, text: "2日" },
      { value: 3, text: "3日" },
      { value: 4, text: "4日" },
      { value: 5, text: "5日" },
      { value: 6, text: "6日" },
      { value: 7, text: "7日" },
      { value: 8, text: "8日" },
      { value: 9, text: "9日" },
      { value: 10, text: "10日" },
      { value: 11, text: "11日" },
      { value: 12, text: "12日" },
      { value: 13, text: "13日" },
      { value: 14, text: "14日" },
      { value: 15, text: "15日" },
      { value: 16, text: "16日" },
      { value: 17, text: "17日" },
      { value: 18, text: "18日" },
      { value: 19, text: "19日" },
      { value: 20, text: "20日" },
      { value: 21, text: "21日" },
      { value: 22, text: "22日" },
      { value: 23, text: "23日" },
      { value: 24, text: "24日" },
      { value: 25, text: "25日" },
      { value: 26, text: "26日" },
      { value: 27, text: "27日" },
      { value: 28, text: "28日" },
      { value: 29, text: "29日" },
      { value: 30, text: "30日" },
      { value: 31, text: "31日" },
      { value: 32, text: "上旬" },
      { value: 33, text: "中旬" },
      { value: 34, text: "下旬" },
      { value: 35, text: "日付指定なし" },
    ];

    const styleItem = [
      { value: 0, text: "選択して下さい" },
      { value: 1, text: "スリム" },
      { value: 2, text: "標準" },
      { value: 3, text: "グラマー" },
      { value: 4, text: "ぽっちゃり" },
      { value: 5, text: "豊満" },
    ];

    const kindItem = [
      { value: 0, text: "選択して下さい" },
      { value: 12, text: "ロリ・妹系" },
      { value: 1, text: "美少女系" },
      { value: 2, text: "ギャル系" },
      { value: 3, text: "お嬢様系" },
      { value: 4, text: "お姉さん系" },
      { value: 5, text: "痴女系" },
      { value: 6, text: "人妻・マダム系" },
      { value: 7, text: "ママ・熟女系" },
    ];

    const items = {
      "itemCommentBody": {
        text: "セラピストコメント",
        rules: "",
        counter: "",
      },
      "managerCommentBody": {
        text: "店長コメント",
        rules: "",
        counter: "",
      },
      "isPickup": {
        text: "このセラピストをピックアップとして表示する",
        rules: "",
        counter: "",
      },
      "weight": {
        text: "体重",
        rules: "numeric|length:3",
        counter: "3",
      },
      "joinShopYear": {
        text: "年",
        rules: "",
        counter: "",
      },
      "joinShopMonth": {
        text: "月",
        rules: "",
        counter: "",
      },
      "joinShopDay": {
        text: "日",
        rules: "",
        counter: "",
      },
      "isNew": {
        text: "このセラピストを新人として表示する",
        rules: "",
        counter: "",
      },
      "isNewEndDay": {
        text: "表示期間",
        rules: "required|max_month_from_today:1",
        counter: "",
      },
      "style": {
        text: "スタイル",
        rules: "",
        counter: "",
      },
      "kind": {
        text: "タイプ",
        rules: "",
        counter: "",
      },
      "blogUrl": {
        text: "PC",
        rules: "url|max:100",
        counter: "100",
      },
      "smartPhoneBlogUrl": {
        text: "スマホ",
        rules: "url|max:100",
        counter: "100",
      },
    };

    const init = async () => {
      // 今年を取得
      const thisYear = new Date().getFullYear();
      // joinShopYearItemに項目を追加(去年、今年、来年)
      joinShopYearItem.push({
        value: Number(thisYear - 1),
        text: thisYear - 1 + "年",
      });
      joinShopYearItem.push({
        value: Number(thisYear),
        text: thisYear + "年",
      });
      joinShopYearItem.push({
        value: Number(thisYear + 1),
        text: thisYear + 1 + "年",
      });
      // cast_propにキーを設定
      for (let key in items) {
        set(state.cast_prop, key, null);
      }

      // 1ヶ月後の日付を取得
      state.today = new Date();
      state.nextMonthDay = utilFunc.getMonthLater(1);
      state.nextMonthDay.setDate(state.nextMonthDay.getDate() - 1);
      console.log(state.today, state.nextMonthDay);

      Object.keys(props.com_data.cast_prop).forEach(function (key) {
        if (
          key == "joinShopYear" ||
          key == "joinShopMonth" ||
          key == "joinShopDay"
        ) {
          set(state.cast_prop, key, Number(props.com_data.cast_prop[key]));
        } else if (key == "isNew" || key == "isPickup") {
          set(state.cast_prop, key, props.com_data.cast_prop[key] == "True");
        } else {
          set(state.cast_prop, key, props.com_data.cast_prop[key]);
        }
      });
      // 読み込み時のデータを保持(保存前に比較するため)
      state.keepData.cast_prop = Object.assign({}, state.cast_prop);
    };

    init();

    // チェック処理
    const checkValid = async () => {
      // 事前チェック処理
      if (state.cast_prop.isNew == false) {
        set(state.cast_prop, "isNewEndDay", null);
      }
      state.errorElement = null;
      const isValid = await ctx.refs.observer.validate();
      if (!isValid) {
        let item_name = "";
        Object.keys(ctx.refs.observer.errors).forEach(function (key) {
          if (ctx.refs.observer.errors[key].length > 0) {
            item_name = key;
            return;
          }
        });
        const errorKey = utilFunc.getKeyByItemText(items, item_name);
        state.errorElement = ctx.refs[errorKey].$el;
      }
      return isValid;
    };

    const setErrorFocus = async () => {
      setTimeout(() => {
        state.errorElement.scrollIntoView({
          block: "center",
        });
      }, 300);
    };

    const save = async () => {
      // 更新前と比べて差分があれば更新処理
      for (let key in state.cast_prop) {
        if (key == "managerCommentBody") {
          set(state.cast_prop, key, refCommentByShopCkeditor.value.editorData);
        }
        if (key == "itemCommentBody") {
          set(state.cast_prop, key, refCommentByCastCkeditor.value.editorData);
        }
      }
      if (!utilFunc.compareObject(state.keepData.cast_prop, state.cast_prop)) {
        const prop_param = [];
        for (let key in state.cast_prop) {
          if (key == "isNew" || key == "isPickup") {
            prop_param.push({
              cast_id: props.com_data.cast_id,
              key: props.com_data.prefix + "__" + key,
              value: state.cast_prop[key] ? "True" : "False",
            });
          } else {
            prop_param.push({
              cast_id: props.com_data.cast_id,
              key: props.com_data.prefix + "__" + key,
              value: state.cast_prop[key] ?? "",
            });
          }
        }
        await CastPropsRepository.save_all({ cast_prop: prop_param })
          .then((response) => {
            if (response.data) {
              // データ追加OK
            }
          })
          .catch((error) => {
            throw (
              "ERROR:cast_manager_wordpress.vue/save CastPropsRepository.save_all (" +
              error +
              ")"
            );
          });
      }
      return true;
    };

    const onHtmlClear = (mode) => {
      let mode_label = "";
      if (mode == "shop") {
        mode_label = "ショップコメント";
      } else {
        mode_label = "セラピストコメント";
      }
      if (
        !window.confirm(
          mode_label + "のHTMLに入力された内容をクリアします。よろしいですか？"
        )
      ) {
        return;
      }
      if (mode == "shop") {
        state.cast_prop.prf_5 = "";
        if (refCommentByShopCkeditor.value) {
          refCommentByShopCkeditor.value.editorData = "";
        }
      } else {
        state.cast_prop.prf_3 = "";
        if (refCommentByCastCkeditor.value) {
          refCommentByCastCkeditor.value.editorData = "";
        }
      }
    };

    return {
      props,
      selectItem,
      setting,
      items,
      joinShopYearItem,
      joinShopMonthItem,
      joinShopDayItem,
      styleItem,
      kindItem,
      ...toRefs(state),
      checkValid,
      setErrorFocus,
      save,
      refCommentByShopCkeditor,
      refCommentByCastCkeditor,
      onHtmlClear,
    };
  },
});
</script>

<style scoped></style>
